<template>
<Main :channel="20210426043" />
</template>

<script>
import Main from './Main.vue'
export default {
    components: {
        Main,
    },
    mounted() {
        window._agl = window._agl || [];
        (function () {
            _agl.push(['production', '_f7L2XwGXjyszb4d1e2oxPybgD']);
            (function () {
                var agl = document.createElement('script')
                agl.type = 'text/javascript'
                agl.async = true
                agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD'
                var s = document.getElementsByTagName('script')[0]
                s.parentNode.insertBefore(agl, s)
            })()
        })()
        
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?b15cb1b76a1f77dbf33e730016cf7d51";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    },
}
</script>
